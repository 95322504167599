import { atom } from "recoil";
import { IUserAuth } from "../types/IUserAuth";

const UserAuth = atom<IUserAuth | null>({
  key: 'UserAuth',
  default: null,
});

export const store = {
  UserAuth,
}