import React from 'react';
import { PublicRoute, AuthenticatedRoute } from './routers/Routes';
import FullPageLayout from './layouts/FullPageLayout';

const QrCode = React.lazy(() => import('./pages/QrCode'))
const ParticipantList = React.lazy(() => import('./pages/ParticipantList'))


export interface Route {
  key: string;
  path: string;
  route: typeof PublicRoute | typeof AuthenticatedRoute;
  layout: typeof FullPageLayout;
  content: React.ReactNode;
  breadcrumb?: React.ComponentType | React.ElementType | string;
  hideBreadcrumb?: boolean;
}

export const routes: Route[] = [
  {
    key: 'QrCode',
    path: '/qrcode',
    layout: FullPageLayout,
    route: PublicRoute,
    content: <QrCode />,
  },
  {
    key: 'ParticipantList',
    path: '/participants',
    layout: FullPageLayout,
    route: PublicRoute,
    content: <ParticipantList />,
  },
]
