import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

export const PublicRoute = Route

export function AuthenticatedRoute(props: RouteProps) {

  return (
    <Route {...props} />
  )
}